<template>
  <div>
    <CCard>
        <CCardHeader>
         <CIcon name="cil-book"/> Study Contents
        <div class="card-header-actions">
            <a 
                href="https://coreui.io/vue/docs/components/card-components" 
                class="card-header-action" 
                rel="noreferrer noopener" 
                target="_blank"
            >
            <small class="text-muted">docs</small>
            </a>
        </div>
        </CCardHeader>
        <CCardBody>
            <CForm>
                <!-- student and teacher condition not any admin v-if="this.role == 3"-->
                <!-- <div class="row"  v-if="this.role == 3"> -->
                <!-- <div class="row"  v-if="this.user_role_id == '6b82d8f3-e879-42de-a8ff-2426b61c9ac9'">
                <div class="col-md-6">
                    <div class="form-group row">
                    <label for="inputPassword" class="col-sm-4 col-form-label" style="margin-top:15px;">{{labels.student}}</label>
                    <div class="col-sm-8">
                        <b-input-group class="mt-3">
                            <template #append>
                            <b-button variant="success" @click="click_me">Search</b-button>
                            </template>
                            <b-form-input v-model="form.student" placeholder="Reg No."></b-form-input>
                        </b-input-group>
                    </div>
                    </div>
                </div>
                <div class="col-md-6" style="margin-top:15px;">
                    <div class="form-group row">
                    <label for="inputPassword" class="col-sm-4 col-form-label">Name</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" v-model="student_name" id="classes" placeholder="" readonly>
                    </div>
                    </div>
                </div>
                </div> -->
                <!-- v-if="this.role == 2" -->
                <div class="row"  v-if="this.role == 2"  >
                <div class="col-md-6">
                    <div class="form-group row">
                    <label for="inputPassword" class="col-sm-4 col-form-label" style="margin-top:15px;">{{labels.teacher}}</label>
                    <div class="col-sm-8">
                        <b-input-group class="mt-3">
                            <template #append>
                            <b-button variant="success" @click="click_me2">Search</b-button>
                            </template>
                            <b-form-input v-model="form.teacher" placeholder="Reg No."></b-form-input>
                        </b-input-group>
                    </div>
                    </div>
                </div>
                <div class="col-md-6" style="margin-top:15px;">
                    <div class="form-group row">
                    <label for="inputPassword" class="col-sm-4 col-form-label">Name</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" v-model="teacher_name" id="classes" placeholder="" readonly>
                    </div>
                    </div>
                </div>
                </div>
                <!-- student and teacher condition not any admin-->
                <!-- Admin condition-->
                <div class="row">
                <div class="col-md-6" >
                    <div class="form-group row">
                    <label for="inputPassword" class="col-sm-4 col-form-label">{{labels.Year}} *</label>
                    <div class="col-sm-8">
                        <select class="form-control"  v-model="form.year">
                        <option value=''>Select Year</option>
                          <option  v-for="(item,index) in academic_items" :key="index" :value="item">{{item.ac_year_description}}</option>
                        </select>
                        <span id="year_err"></span>
                    </div>
                    </div>
                </div>

                <div class="col-md-6"  v-if="this.role == 1">
                    <div class="form-group row">
                    <label for="inputPassword" class="col-sm-4 col-form-label">{{labels.district}}</label>
                    <div class="col-sm-8">
                        <select class="form-control"  v-model="form.district">
                        <option value=''>Select District</option>
                        <option value= 1>Dist - 1</option>
                        <option value= 2>Dist - 2</option>
                        </select>
                        <span id="district_err"></span>
                    </div>
                    </div>
                </div>
                </div>
                <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                    <label for="inputPassword" class="col-sm-4 col-form-label">{{labels.title_upload}} *</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" id="classes"  v-model="form.title_upload" placeholder="Enter Title">
                         <span id="title_err"></span>
                    </div>
                    </div>
                </div>
                <!--  <div class="col-md-6">
                    <div class="form-group row">
                    <label for="inputPassword" class="col-sm-4 col-form-label">{{labels.class}}</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" id="classes" placeholder="Enter Class">
                    </div>
                    </div>
                </div> -->
                </div><br/>
                <div class="row">
                

                <div class="col-md-6">
                    <div class="form-group row">
                    <label for="inputPassword" class="col-sm-4 col-form-label">{{labels.school}} *</label>
                    <div class="col-sm-8">
                        <select class="form-control"  v-model="form.school">
                        <option value=''>Select School</option>
                        <option  v-for="(item,index) in school_list" :key="index" :value="item">{{item.school_name}}</option>
                        
                        </select>
                         <span id="school_err"></span>
                    </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                    <label for="inputPassword" class="col-sm-4 col-form-label">{{labels.subject}} *</label>
                    <div class="col-sm-8">
                        <select class="form-control"  v-model="form.subject">
                        <option value=''>Select Subject</option>
                        <option v-for="(item,index) in subject_items" :key="index" :value="item">{{item.subject_name}}</option>
                        
                        </select>
                         <span id="subject_err"></span>
                    </div>
                    </div>
                </div>
                </div><br/>
                <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                    <label for="inputPassword" class="col-sm-4 col-form-label">{{labels.class}} *</label>
                    <div class="col-sm-8">
                        <select class="form-control"  v-model="form.class">
                        <option value=''>Select Class</option>
                        <option  v-for="(item,index) in class_items" :key="index" :value="item">{{item.class_standard}}</option>
                        
                        </select>
                         <span id="class_err"></span>
                    </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                    <label for="inputPassword" class="col-sm-4 col-form-label">{{labels.division}} *</label>
                    <div class="col-sm-8">
                         <input type="text" class="form-control"  v-model="form.division" id="classes" placeholder="Enter Division">
                        <!-- <select class="form-control"  v-model="form.division">
                        <option value=''>Select Division</option>
                        <option v-for="(item,index) in division_items" :key="index" :value="item.division_id">{{item.division}}</option>
                        
                        </select> -->
                         <span id="division_err"></span>
                    </div>
                    </div>
                </div>
                </div><br/>
                
            
                <div class="row">
                

                <div class="col-md-6">
                    <div class="form-group row">
                    <label for="inputPassword" class="col-sm-4 col-form-label">{{labels.file}}</label>
                    <div class="col-sm-8">
                        <input
                            type="file"
                            id="file_attachment"
                            ref="file_attachment"
                            v-on:change="handleFileUpload()"
                            placeholder
                        />
                    </div>
                    </div>
                </div>
                </div><br/>
                <div class="row">
                <div class="col-md-12">
                    <div class="form-group row">
                    <label for="inputPassword" class="col-sm-2 col-form-label">{{labels.description}}</label>
                    <div class="col-sm-10">
                        <textarea class="form-control" Placeholder="Description about upload will go here..."  v-model="form.description" id="exampleFormControlTextarea1" rows="3"></textarea>
                    </div>
                    </div>
                </div>
                </div>
            </CForm>
            <div class="row">
                <div class="col-md-8"></div>
                    <div class="col-md-4">
                        <CButton color="primary" @click="save_data">{{labels.submit}}</CButton>&nbsp;&nbsp;
                        <CButton color="danger"   @click="onReset">{{labels.reset}}</CButton>
                    </div>
                </div>
        </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from 'axios';
import apis from "../apis/index";
import $ from 'jquery';
import Swal from "sweetalert2";
const base_url=process.env.VUE_APP_BASE_URL;
export default {
  name: "study_content",
  data(){
      return{
        content_data:[],
        file_url:"",
        user_name:"",
        isLoading: false,
        fullPage: true,
        file_attachment:'',
        role:"",
        user_role_id:"",
        student_name:"",
        teacher_name:"",
        academic_items:[],
        class_items:[],
        subject_items:[],
        division_items:[],
        school_list:[],
        user_id:"",
        user_role_id:"",

        form: {
            title_upload:"",
            userName: "",
            password: "",
            class:"",
            subject:"",
            file:"",
            description:"",
            school:"",
            district:"",
            year:"",
            teacher:"",
            student:"",
            division:"",
            checked: []
        },
        foods: ["Remember Me"],
        show: true,
        labelsEnglish: {
            title_upload:'Title',
            title: "Upload Documents",
            subject: "Subject ",
            class: "Class",
            file: "File",
            image: "Image",
            district:"District",
            school:"School",
            Year:"Academic Year",
            division:"Division",
            student:"Student",
            teacher:"Teacher",

            description: "Description",
            submit: "Submit",
            reset: "Reset"
        },
        labels: {
            title: "Upload Study Material",
            title_upload:'Title',
            subject: "Subject",
            Year:"Academic Year",
            class: "Class",
            file: "File",
            district:"District",
            school:"School",
            division:"Division",
            student:"Student",
            teacher:"Teacher",
            image: "Image",
            description: "Description",
            submit: "Submit",
            reset: "Reset"
        },
        labelsMarathi: {
            title_upload:'शीर्षक',
            title: "महा-विद्या मध्ये आपले स्वागत आहे",
            subject: "विषय",
            Year:"Academic Year",
            class: "वर्ग",
            file: "फाईल",
            district:"District",
            school:"School",
            division:"Division",
            student:"Student",
            teacher:"Teacher",
            description: "वर्णन",
            submit: "स्वीकारा",
            reset: "पुनर्स्थापित"
        }
      }
  },
  components: {
  },
  methods:{
      get_academic_year(){
            var promise = apis.list_AcademicYears().then( response => {
            this.academic_items = response.data;
            }).catch( error => {

            });
        },
        get_class(){
        var promise = apis.list_ClassMasters().then( response => {
            this.class_items = response.data;
            }).catch( error => {

            });
        },
        get_division(){
        var promise = apis.list_DivisionMasters().then( response => {
            this.division_items = response.data;
            }).catch( error => {

            });
        },
        get_teacher(){
        var promise = apis.list_Teachers().then( response => {
            this.teacher_items = response.data;
            }).catch( error => {

            });
        },
        get_subject(){
        var promise = apis.list_Subjects().then( response => {
            this.subject_items = response.data;
            }).catch( error => {

            });
        },
        get_division(){
        var promise = apis.list_DivisionMasters().then( response => {
            this.division_items = response.data;
            }).catch( error => {

            });
        },
        click_me(){
        this.isLoading = true;
        var data = {
            'student_id': this.form.student
        }
        var promise = apis.studentbyid(data).then( response => {
            this.isLoading = false;
            this.student_name = response.data[0].student_name;
        }).catch( error => {

        })
        },
        click_me2(){
        this.isLoading = true;
        var data = {
            'teacher_id': this.form.teacher
        }
        var promise = apis.teacherbyid(data).then( response => {
            this.isLoading = false;
            this.teacher_name = response.data[0].teacher_name;
        }).catch( error => {

        })
        },
        handleFileUpload() {
        this.file_attachment = this.$refs.file_attachment.files[0];
        },
        save_data(){
           // console.log(this.form.year);
           // return;
            if(this.form.year == '' || this.form.year == undefined){
                $("#year_err").css("color","red").text("Please Select Year *").show();
                return;
            }
            if(this.form.title_upload == '' || this.form.title_upload == undefined){
                $("#title_err").css("color","red").text("Please Enter Title *").show();
                return;
            }
            if(this.form.school == '' || this.form.school == undefined){
                $("#school_err").css("color","red").text("Please Select School *").show();
                return;
            }
            if(this.form.subject == '' || this.form.subject == undefined){
                $("#subject_err").css("color","red").text("Please Select Subject *").show();
                return;
            }
            if(this.form.class == '' || this.form.class == undefined){
                $("#class_err").css("color","red").text("Please Select Class *").show();
                return;
            }
            if(this.form.division == '' || this.form.division == undefined){
                $("#division_err").css("color","red").text("Please Select Division *").show();
                return;
            }
            
            this.isLoading =true;
            var filetype = this.file_attachment.type;
            var filepath = this.file_attachment.name;
            var data = {
            ContentType: filetype,
            FilePath: filepath
            }
           // console.log(data);
            var promise = apis.get_url(data);
            promise.then(response => {
                let signedUrl = response.data;
                
                 console.log("response");
                console.log(response.data);
                this.file_url = response.data +"/"+this.file_attachment.name;
                let x = this;
                var options = {
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": filetype
                    }
                };
                this.isLoading = false;
                    var stu_id = "";
                    var teacher_id = "";
                    var stu_name = "";
                    var te_name = "";
                    if(this.user_role_id == '8290f938-b20a-42e3-c201-08d8f3241655'){
                        stu_id = this.user_id;
                        stu_name = this.user_name;
                        te_name = "";
                        teacher_id = '00000000-0000-0000-0000-000000000000';

                    }else{
                        stu_id = '00000000-0000-0000-0000-000000000000';
                        teacher_id = this.user_id;
                        stu_name = "";
                        te_name = this.user_name;
                    }
                    var data1 = {
                    'title':this.form.title_upload,
                    'file_type':this.file_attachment.name,
                    'file_path': this.file_url,
                    'remarks': this.form.description,
                    'school_id': this.form.school.school_id,
                    'district_id':'8ee1d113-3d0d-4f94-12ee-08d8f417f6e3',
                    'class_id': this.form.class.class_id,
                    'division_id': 1,
                    'student_id': stu_id,
                    'subject_id': this.form.subject.subject_id,
                    'subject_name': this.form.subject.subject_name,
                    'teacher_id':teacher_id,
                    'ac_year_id': this.form.year.ac_year_id,
                    'is_active': true,
                    'school_name':this.form.school.school_name,
                    'district_name': 'Mumbai',
                    'class_name': this.form.class.class_name,
                    'division_name': this.form.division,
                    'student_name': stu_name,
                    'teacher_name': te_name,
                    'year': this.form.year.ac_year_description,
                    'created_date': this.D_M_Y_formatDate(new Date())
                    }
                    //console.log(data1);
                   // return;
                var promise = apis.add_content(data1);
                promise.then(response => {
                    Swal.fire({
                        width: 400,
                        title: "Your Content Uploaded Successfully !!",
                        Text: "Your Content Uploaded Successfully !!",
                    });
                    this.form.title_upload = "";
                    this.form.division = "";
                    this.form.class = "";
                    this.form.description = "";
                    this.form.school = "";
                    this.form.subject = "";
                this.file_url = "";
                return axios.put(signedUrl, this.file_attachment,options);
             })
             .catch(error =>{
                 console.log(error);
             });
            
            // axios.post('https://3t23rkamvh.execute-api.ap-south-1.amazonaws.com/Prod/api'+'/PreSignedUrl', data).then(response => {
            // let signedUrl = response.data;
            // this.file_url = signedUrl;
            // let x = this;
            // var options = {
            //     headers: {
            //     "Access-Control-Allow-Origin": "*",
            //     "Content-Type": filetype
            //     }
            // };
            // this.isLoading = false;
            // return axios.put(signedUrl, this.file_attachment,options);
            
            // }).
            // then(resp => {
            // console.log(resp);
            // if (resp.status == 200) {
            //     this.isLoading = false;
            // } else {
            //     this.$fire({
            //     text: "Error in File Upload !!",
            //     type: "",
            //     timer: 3000,
            //     size:"xs",
            //     width:350,
            //     heightAuto: 'false'
            //     });
            // }
            // }).
            // catch(error => {
            // console.log(error);
            // });
       
           
            // this.form.title_upload = "";
            // this.form.division = "";
            // this.form.class = "";
            // this.form.description = "";
            // this.form.school = "";
            // this.form.subject = "";
           // this.file_url = "";


        // }).catch( error => {
        //     console.log(error);
        // })
            
            })
            
        },
        get_school(){
        
        var promise = apis.list_Schools().then( response => {
        
            this.school_list = response.data;
        }).catch( error => {

        })
        },
        onReset(event) {
        this.form.userName = "";
        this.form.password = "";
        this.form.checked = [];
        this.show = false;
        this.$nextTick(() => {
            this.show = true;
        });
        },
     D_M_Y_formatDate(date_parm)
        {
            let date = new Date(date_parm);
            let dd = date.getDate();
            let mm = date.getMonth() + 1;
            let yyyy = date.getFullYear();
            let new_date = yyyy + "-" + "0"+mm + "-" +dd ;
            this.receipt_new_date = new_date;
            return new_date;
        },
      get_content(){
          var data = {
              id: "D5C85475-FDEB-408B-B9D8-08D90FC5DD42"
          };
          axios.get('https://localhost:44331/api/Contents/D5C85475-FDEB-408B-B9D8-08D90FC5DD42').then(response => {
              console.log(response);
              this.content_data = response.data;
          }).catch(error => {
              console.log(error);
          })
      }
  },
  created(){
    let user_id = this.$session.get('user_id');
    if(user_id == undefined || user_id == null)
    {
      return '/';
      //this.$router.push('/auth/login');
    } 
    this.user_id= localStorage.getItem('id');
    this.user_name = localStorage.getItem('user_name');
    this.user_role_id = localStorage.getItem('user_role_id');
   // this.get_content();
    this.get_academic_year();
    this.get_class();
    this.get_division();
    this.get_class();
    this.get_subject();
    this.get_school();
          
  }
};
</script>
